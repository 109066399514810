import { ImageSizeType } from 'common/enums/ImageSizeType'
import Env from 'Env'
import { ImageModel } from 'models/core/ImageModel'

export const GenerateImageLink = (image: ImageModel, desiredFormat?: ImageSizeType): string => {
  const imageData = image.data

  if (!imageData) {
    return ''
  }

  if (desiredFormat) {
    if (imageData.attributes.formats[desiredFormat]) {
      return Env.BaseUrl + imageData.attributes.formats[desiredFormat].url
    }
  }

  if (imageData.attributes.url) {
    return imageData.attributes.url
  }
  return ''
}
