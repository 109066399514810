import { ImageModel } from 'models/core/ImageModel'
import React, { FunctionComponent } from 'react'
import { GenerateImageLink } from 'utils/GenerateImageLink'
import { ImageSizeType } from 'common/enums/ImageSizeType'
import Env from 'Env'
import Image, { ImageLoader } from 'next/image'

type Props = {
  data: ImageModel | undefined
  imageSize?: ImageSizeType
  extraClassNames?: string
  isFullWidth?: boolean
}

// Using custom srcSet

const ResponsiveImage: FunctionComponent<Props> = (props) => {
  const propsData = props.data && props.data.data
  const formats = propsData?.attributes.formats
  const height = propsData?.attributes.height!!
  const width = propsData?.attributes.width!!
  const link = props.data ? GenerateImageLink(props.data, props.imageSize) : ''
  const calculateWidthAndHeight = () => {
    if (props.isFullWidth) {
      return { height, width }
    }
    if (propsData?.attributes.ext === '.svg') {
      return { height, width }
    }
    return { height: Math.round(height / 3), width: Math.round(width / 3) }
  }
  const processed = calculateWidthAndHeight()
  const generateSrcSet = () => {
    if (propsData?.attributes.ext === '.svg') {
      return undefined
    }
    const images: string[] = []
    if (!formats) return undefined
    Object.keys(formats).forEach((res) => {
      const targetImg = formats[res]
      if (res !== 'thumbnail') {
        images.push(`${Env.StrapiUploadUrl}${targetImg.hash}${targetImg.ext} ${res}`)
      }
    })
    return images.join(',')
  }
  return (
    <img
      className={props.extraClassNames ? props.extraClassNames : ''}
      height={processed.height}
      width={processed.width}
      src={link}
      loading="lazy"
      alt={propsData?.attributes.alternativeText || ''}
      sizes="70vw"
      srcSet={generateSrcSet()}
    />
  )
}

export default ResponsiveImage
