import { ButtonModel } from 'models/core/ButtonModel'
import { ButtonType } from 'models/enums/ButtonType'
import React, { FunctionComponent } from 'react'
import ResponsiveImage from './ResponsiveImage'
import { ImageSizeType } from 'common/enums/ImageSizeType'

type Props = {
  data: ButtonModel
  imageSize?: ImageSizeType
  extraClassNames?: string
}

const Button: FunctionComponent<Props> = (props) => {
  const button = props.data

  if (button.type === ButtonType.OnlyImage) {
    return (
      <a href={button.link} id={button.htmlId ?? undefined}>
        <ResponsiveImage imageSize={props.imageSize} data={button.backgroundImage} extraClassNames={`cursor-pointer ${props.extraClassNames}`} />
      </a>
    )
  }

  if (button.type === ButtonType.WithIcon) {
    return (
      <a href={button.link} id={button.htmlId ?? undefined} className="btn-orange cursor-pointer flex items-center justify-center gap-x-2 w-40">
        <ResponsiveImage data={button.icon} extraClassNames="w-5" />
        <span>{button.text}</span>
      </a>
    )
  }

  return (
    <a className="btn-orange cursor-pointer" id={button.htmlId ?? undefined} href={button.link}>
      {button.text}
    </a>
  )
}

export default Button
