export const HasClassName = (
  element: HTMLElement | null,
  className: string
): boolean => {
  if (element) {
    return element.classList.contains(className)
  }

  return false
}
